import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

function Nyheitsbrev() {
  const [unchecked, setunchecked] = useState("true")
  return (
    <Layout>
      <h1 className="text-2xl text-yellow-700 font-bold text-center py-6">
        Meld deg på nyheitsbrevet vårt
      </h1>
      <div className="grid grid-cols-2 gap-10">
        {/* Info tekst */}
        <div>
          <p>
            Meld deg på nyheitsbrevet vårt ved å bruke skjemaet. Vi vil då sende
            deg e-post om aktivitetane våre med jamne mellomrom. Epostadressa di
            vil ikkje verte brukt til noko anna enn å informere deg om
            aktivitetane våre.
          </p>
          <p className="mt-2 text-gray-600 font-thin">
            Ønskjer du å melde deg av nyheitsbrevet sender du ein e-post{" "}
            <a
              className="text-yellow-800 hover:text-yellow-600"
              href="mailto:post@jolsteradventure.no"
            >
              post@jolsteradventure.no
            </a>{" "}
            så fjernar vi deg frå e-postlista.
          </p>
        </div>

        {/* Skjema */}
        <div className="mb-12">
          <form
            className="bg-yellow-50 shadow rounded px-8 pt-6 pb-8 mb-4"
            method="POST"
            action="../../nyheitsbrev.php"
          >
            <div className="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Namn
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                name="namn"
                type="text"
                placeholder="Namn"
              />
            </div>

            <div className="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="epost"
              >
                Epostadresse
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="epost"
                name="epost"
                type="email"
                placeholder="Epostadresse"
              />
            </div>
            <input
              className=""
              onChange={() => {
                setunchecked(unchecked === "" ? "true" : "")
              }}
              name="personernerklering"
              id="personvernerklering"
              type="checkbox"
            />
            <label
              className=" text-gray-700 text-sm transform -translate-y-0.5"
              htmlFor="personvernerklering"
            >
              Eg har lest{" "}
              <Link
                className="text-green-800 hover:text-green-600"
                to="/personvernerklering/"
              >
                personvernerklæringa
              </Link>{" "}
              og samtykker i at Jølster Adventure kan behandle
              personopplysningar om meg.
            </label>

            <div className="modal-footer px-4 flex justify-end gap-3 rounded-b-md">
              <button
                disabled={unchecked}
                className="mt-6 disabled:bg-gray-400 disabled:cursor-default disabled:hover:bg-gray-400 disabled:text-gray-300 bg-green-400 hover:bg-green-500 text-green-900 rounded px-3 py-1.5 my-2"
              >
                Meld deg på nyheitsbrevet
              </button>
              
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Nyheitsbrev
